import { Dispatch, useEffect, useMemo, useRef, useState } from "react";
import { Avatar, Box } from "@twilio-paste/core";
import { useTheme } from "@twilio-paste/theme";

import {
  MessageStatus,
  ReduxMessage,
} from "../../store/reducers/messageListReducer";
import SendingIcon from "../icons/Sending";
import DeliveredIcon from "../icons/Delivered";
import ReadIcon from "../icons/Read";
import FailedIcon from "../icons/Failed";
import BellMuted from "../icons/BellMuted";

import { NOTIFICATION_LEVEL } from "../../constants";
import { SetSidType, SetUnreadMessagesType } from "../../types";
import { getMessageStatus, readConversation } from "../../api";

import * as _ from "lodash";

import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import { ReduxConversation } from "../../store/reducers/convoReducer";
import { ReduxParticipant } from "../../store/reducers/participantsReducer";
import { useDispatch, useSelector } from "react-redux";
import { Action, bindActionCreators } from "redux";
import { AppState, actionCreators } from "../../store";
import { incrementUnreadCount } from "../../store/action-creators";
import { Conversation } from "@twilio/conversations";
// unreadMessages
TimeAgo.addDefaultLocale(en);

interface SingleConvoProps {
  convoId: string;
  setSid: SetSidType;
  currentConvoSid: string;
  lastMessage: string;
  myMessage: ReduxMessage | false;
  convo: ReduxConversation;
  updateUnreadMessages: SetUnreadMessagesType;
  onClick: () => void;
  participants: ReduxParticipant[];
  messages: ReduxMessage[];
  typingInfo: string[];
  conversations: ReduxConversation[];
  allParticipants: any;
}

const measureWidth = (text: string): number => {
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  if (!context) {
    return 0;
  }
  context.font = "bold 14px Inter";
  return context.measureText(text).width;
};

export function updateConversationIndexAndUnreadCount(
  props: SingleConvoProps,
  unread$: {
    unreadConvos: {
      sid: string;
      amount: number;
    }[];
    accumulator: number;
  },
  convo: Conversation | ReduxConversation,
  setCurrentConversationIndex: (index: number) => void
) {
  console.log(props.convoId, convo.sid);
  const index = props.conversations.findIndex((c) => c.sid === props.convoId);
  const idx = unread$.unreadConvos.findIndex((x) => x.sid === convo.sid);
  if (idx >= 0 && unread$.unreadConvos[idx].amount > 0) {
    incrementUnreadCount(convo.sid, -unread$.unreadConvos[idx].amount);
    updateUnreadCount(props.participants, props.convo);
  }
  setCurrentConversationIndex(index);
}

function calculateUnreadMessagesWidth(count: number) {
  if (count === 0 || !count) {
    return 0;
  }
  return measureWidth(count.toString()) + 32;
}

function truncateMiddle(text: string, countWidth: number) {
  const width = measureWidth(text);
  if (width > 288 - countWidth) {
    const textLength = text.length;
    const avgLetterSize = width / textLength;
    const nrOfLetters = (288 - countWidth) / avgLetterSize;
    const delEachSide = (textLength - nrOfLetters + 1) / 2;
    const endLeft = Math.floor(textLength / 2 - delEachSide);
    const startRight = Math.ceil(textLength / 2 + delEachSide);
    return text.substr(0, endLeft) + "..." + text.substr(startRight);
  }
  return text;
}

function getLastMessageTime(messages: ReduxMessage[]) {
  const lastMessageDate = _.last(messages)?.dateCreated;
  if (!lastMessageDate) {
    return "";
  }
  // return lastMessageDate.getDate();
  return new TimeAgo("en-US").format(lastMessageDate, "twitter-now");
}

async function updateUnreadCount(participants: any, unreadConvo: any) {
  const fromUserId = localStorage.getItem("username");
  const toUserId = participants.find(
    (p: any) => p.identity !== fromUserId
  )?.identity;
  const senderAccountType = localStorage.getItem("type");
  const receiverAccountType =
    senderAccountType === "company" ? "worker" : "company";
  if (senderAccountType && fromUserId && toUserId) {
    const temp = readConversation(
      unreadConvo.sid,
      toUserId,
      fromUserId,
      receiverAccountType,
      senderAccountType
    );
    temp.then((result) => {
      console.log(result);
    });
  }
}

const ConversationView: React.FC<SingleConvoProps> = (
  props: SingleConvoProps
) => {
  const { convo, convoId, myMessage, lastMessage, participants } = props;
  const [backgroundColor, setBackgroundColor] = useState<any>();
  const [isHover, setIsHover] = useState<boolean>();
  const [unreadMessagesCount, setUnreadMessagesCount] = useState(0);
  const unreadMessagesCountRef = useRef(unreadMessagesCount);
  // const currentUser = JSON.parse(localStorage.getItem("currentUser") || "{}");
  // const participants2 = props.participants.filter(
  //   (p) => p.identity !== currentUser.identity
  // );
  const unread$ = useSelector(
    (state: AppState) => state.unreadConversationCount
  );
  const conversations = useSelector((state: AppState) => state.convos);
  const convNameString =
    (participants[0] && participants[0].friendlyName) || "Unknown User";
  const brandName =
    (participants[0] && participants[0].attributes.brandName) || "";
  const isParticipantSupport =
    participants[0] && participants[0].attributes.isOnCall ? true : false;
  const isOnline = participants[0]?.isOnline || false;
  const title = truncateMiddle(
    convNameString ?? convo.sid,
    calculateUnreadMessagesWidth(unreadMessagesCount)
  );

  let profilePicture = undefined;
  if (participants[0]?.attributes?.image?.indexOf("http") >= 0) {
    profilePicture = participants[0]?.attributes?.image;
  }
  const theme = useTheme();
  const muted = convo.notificationLevel === NOTIFICATION_LEVEL.MUTED;

  const [lastMsgStatus, setLastMsgStatus] = useState("");
  const time = getLastMessageTime(props.messages);
  const dispatch = useDispatch();
  const { setCurrentConversationIndex, incrementUnreadCount } =
    bindActionCreators(actionCreators, dispatch);

  const convoIndex = useSelector(
    (state: AppState) => state.currentConversationIndex
  );
  const bgkColor =
    isHover ||
    (convoIndex >= 0 && props.conversations[convoIndex].sid === props.convoId)
      ? "#cfdeea"
      : undefined;

  useEffect(() => {
    const unreadConvo = unread$.unreadConvos.find(
      (x) => convoIndex >= 0 && x.sid === conversations[convoIndex].sid
    );
    if (unreadConvo && unreadConvo.amount > 0) {
      incrementUnreadCount(unreadConvo.sid, -unreadConvo.amount);
      updateUnreadCount(props.participants, props.convo);
    }
  }, [unread$.accumulator]);

  useEffect(() => {
    if (myMessage && !props.typingInfo.length) {
      getMessageStatus(myMessage, props.participants).then((statuses) => {
        if (statuses[MessageStatus.Read]) {
          setLastMsgStatus(MessageStatus.Read);
          return;
        }
        if (statuses[MessageStatus.Delivered]) {
          setLastMsgStatus(MessageStatus.Delivered);
          return;
        }
        if (statuses[MessageStatus.Failed]) {
          setLastMsgStatus(MessageStatus.Failed);
          return;
        }
        if (statuses[MessageStatus.Sending]) {
          setLastMsgStatus(MessageStatus.Sending);
          return;
        }
      });
    }
  }, [convo, myMessage, lastMessage, props.participants, props.typingInfo]);

  useEffect(() => {
    const unreadConvo = unread$.unreadConvos.find(
      (x) => x.sid === props.convo.sid
    );
    setUnreadMessagesCount(unreadConvo?.amount as number);
  }, [unread$.accumulator]);

  useEffect(() => {
    unreadMessagesCountRef.current = unreadMessagesCount;
  }, [unreadMessagesCount]);

  return (
    <Box
      style={{
        // width: 250,
        padding: 10,
        cursor: "pointer",
        backgroundColor: bgkColor,
        borderRadius: 10,
        color: "#0f265c",
        marginBottom: 5,
      }}
      id={convoId}
      className="name"
      onMouseOver={() => {
        setIsHover(true);
        if (convo.sid === props.currentConvoSid) {
          return;
        }
        setBackgroundColor("#cfdeea");
      }}
      onMouseOut={() => {
        setIsHover(false);
        if (convo.sid === props.currentConvoSid) {
          return;
        }
        setBackgroundColor("");
      }}
      onClick={() => {
        updateConversationIndexAndUnreadCount(
          props,
          unread$,
          convo,
          setCurrentConversationIndex
        );
        props.onClick();
      }}
    >
      <Box display="flex">
        <Box style={{ marginRight: 10, position: "relative" }}>
          <Avatar
            size="sizeIcon90"
            name={title}
            color="decorative20"
            src={profilePicture}
          />
          {isOnline && (
            <span
              style={{
                height: 13,
                width: 13,
                backgroundColor: "#00BA5D",
                borderRadius: "50%",
                display: "inline-block",
                position: "absolute",
                top: 0,
                right: 0,
              }}
            />
          )}
        </Box>

        <Box style={{ width: "100%" }}>
          <Box display="flex">
            <Box
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                width: 170,
                fontFamily: "Source Sans Pro, sans-serif",
                fontWeight:
                  unreadMessagesCountRef.current === 0 ? undefined : 700,
                fontSize: 14,
                // color: muted
                //   ? theme.textColors.colorTextInverseWeaker
                //   : theme.textColors.colorText,
              }}
            >
              {muted ? <BellMuted /> : null}

              <div
                style={{
                  verticalAlign: "top",
                  paddingLeft: muted ? 4 : 0,
                  fontWeight: "bold",
                }}
              >
                {title}
              </div>
              <div>
                {!isParticipantSupport
                  ? brandName === "No company name"
                    ? "Talent"
                    : ""
                  : ""}
              </div>
            </Box>
            <Box
              style={{
                whiteSpace: "nowrap",
                fontSize: 12,
                width: 67,
                textAlign: "right",
                paddingRight: 4,
              }}
            >
              {time}
            </Box>
          </Box>
          <Box
            style={{
              paddingTop: 4,
              // color: textColor,
              fontWeight: theme.fontWeights.fontWeightNormal,
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              <Box
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  width: 174,
                  fontWeight:
                    unreadMessagesCountRef.current === 0 ? undefined : 700,
                  color: "#9d9d9d",
                }}
              >
                {lastMessage}
              </Box>
            </Box>
            {unreadMessagesCountRef.current > 0 && (
              <Box
                style={{
                  width: 61,
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <Box
                  backgroundColor="colorBackgroundBrandStronger"
                  color="colorTextInverse"
                  fontFamily="fontFamilyText"
                  fontSize="fontSize30"
                  lineHeight="lineHeight30"
                  paddingLeft="space30"
                  paddingRight="space30"
                  style={{
                    borderRadius: "100%",
                    opacity: muted ? 0.2 : 1,
                    width: 16,
                    height: 16,
                    marginRight: "5px",
                  }}
                ></Box>
              </Box>
            )}
            {!props.typingInfo.length ? (
              <Box>
                {lastMsgStatus === MessageStatus.Sending && props.myMessage && (
                  <Box style={{ paddingRight: 5 }}>
                    <SendingIcon color="rgb(150 160 169)" />
                  </Box>
                )}
                {lastMsgStatus === MessageStatus.Delivered && props.myMessage && (
                  <Box style={{ paddingRight: 5 }}>
                    <DeliveredIcon color="rgb(150 160 169)" />
                  </Box>
                )}
                {lastMsgStatus === MessageStatus.Failed && props.myMessage && (
                  <Box style={{ paddingRight: 5 }}>
                    <FailedIcon color="red" />
                  </Box>
                )}
                {lastMsgStatus === MessageStatus.Read && props.myMessage && (
                  <Box style={{ paddingRight: 5 }}>
                    <ReadIcon color="#00BA5D" />
                  </Box>
                )}
              </Box>
            ) : null}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ConversationView;
