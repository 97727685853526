import { Avatar } from "@twilio-paste/avatar";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { animated, useSpring } from "react-spring";
import { actionCreators, AppState } from "../../store";
import { Conversation } from "@twilio/conversations";
import { ReduxConversation } from "../../store/reducers/convoReducer";
import { setCurrentConversationIndex } from "../../store/action-creators";
import { bindActionCreators } from "redux";
import styles from "../../styles";

interface ConversationNotificationProp {
  key: number;
  notificationKey: number;
  authorName: string;
  brandName?: string;
  message: string;
  imageUri?: string;
  conversations: ReduxConversation[] | Conversation[];
  convoSidSource: string;
  totalChildren: number;
  onDismiss?: (reason?: any) => void;
  onRest?: () => void;
}

const ConversationNotification: React.FC<ConversationNotificationProp> = (
  prop: ConversationNotificationProp
) => {
  const [toggle, setToggle] = useState(true);
  const [cooldown, setCoolDown] = useState(false);
  const [initX, setInitX] = useState(0);
  const [swipeDirection, setSwipeDirection] = useState(1);
  const NOTIFICATION_WAIT_TIME = 8000;
  const sliderRef = useRef<HTMLDivElement>(null);
  const transformStyles = useSpring({
    left: toggle ? `${-window.innerWidth * swipeDirection}px` : "0px",
    config: { tension: 1000, friction: 200 },
    onRest: () => {
      if (toggle && prop.onDismiss) {
        prop.onDismiss({
          message: "animation_ended",
          key: prop.notificationKey,
        });
      }
    },
  });
  const dispatch = useDispatch();
  const { setCurrentConversationIndex } = bindActionCreators(
    actionCreators,
    dispatch
  );

  const [clock, setClock] = useState<NodeJS.Timeout | null>();

  const resetState = () => {
    if (clock) {
      clearTimeout(clock);
    }
    setToggle(true);
    setCoolDown(false);
  };

  const setState = () => {
    setToggle(false);
    setCoolDown(true);
  };

  useEffect(() => {
    if (prop.message.length > 0 && !cooldown) {
      setState();
      const mClock = setTimeout(() => {
        resetState();
      }, NOTIFICATION_WAIT_TIME);
      setClock(mClock);
    }
  }, [prop.message]);

  return (
    <animated.div
      ref={sliderRef}
      onTouchStart={(event) => {
        setInitX(event.touches[0].clientX);
      }}
      onTouchMove={(event) => {
        const clientX = event.touches[0].clientX;
        const dx = clientX - initX;
        if (Math.abs(dx) > 10) {
          setSwipeDirection(-Math.sign(dx));
          resetState();
        }
        setInitX(clientX);
      }}
      style={{
        ...styles.conversationNotificationContainer,
        ...transformStyles,
      }}
    >
      <div style={styles.conversationNotificationCard}>
        <div
          onClick={() => {
            const conversationIndex = prop.conversations.findIndex(
              (c) => c.sid === prop.convoSidSource
            );
            setCurrentConversationIndex(conversationIndex);
          }}
          style={{
            display: "flex",
          }}
        >
          <Avatar
            size="sizeIcon80"
            name={prop.authorName}
            src={prop.imageUri}
            color="decorative20"
          />
          <div
            style={{
              marginLeft: "10px",
              width: "100%",
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  fontSize: "16px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  {prop.authorName}
                </div>
                <div style={styles.dotSeparator}></div>
                <div style={{ color: "#9e9e9e" }}>
                  {prop.brandName ?? "Talent"}
                </div>
              </div>
            </div>

            <div
              style={{
                fontSize: "16px",
                marginTop: "4px",
              }}
            >
              {prop.message.length > 128
                ? prop.message.substring(0, 128) + "..."
                : prop.message}
            </div>
          </div>
        </div>
        <div
          onClick={() => {
            resetState();
          }}
          style={styles.btnConversationNotificationDismiss}
        >
          Dismiss
        </div>
      </div>
    </animated.div>
  );
};

export default ConversationNotification;
